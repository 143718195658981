<template>
  <div class="modal modal_custom" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Cambiar contraseña</p>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="currentPassword">
                Contraseña actual
              </label>
              <input
                id="currentPassword"
                ref="currentPassword"
                class="input"
                type="password"
                name="currentPassword"
                v-model="currentPassword"
                placeholder="********"
                v-validate="'required'"
                data-vv-as="de la contraseña actual"
              >
              <span v-show="vverrors.has('currentPassword')" class="help is-danger">{{ vverrors.first('currentPassword') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="password">
                Nueva contraseña
              </label>
              <input
                id="password"
                ref="password"
                class="input"
                type="password"
                name="password"
                v-model="password"
                placeholder="********"
                v-validate="'required'"
                data-vv-as="de la nueva contraseña"
              >
              <span v-show="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label" for="passwordConfirm">
                Confirmar contraseña
              </label>
              <input
                id="passwordConfirm"
                class="input"
                type="password"
                name="passwordConfirm"
                placeholder="*******"
                v-validate="'required|confirmed:password'"
                data-vv-as="de confirmar contraseña"
                autocomplete="confirm-new-password"
              >
              <span v-show="vverrors.has('passwordConfirm')" class="help is-danger">{{ vverrors.first('passwordConfirm') }}</span>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-space-around">
        <button
          class="button is_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button btn_violet"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="changePasswordAction"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalChangePassword',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPassword: null,
      password: null
    }
  },
  methods: {
    async changePasswordAction () {
      if (await this.$validator.validateAll()) {
        const data = {
          currentPassword: this.currentPassword,
          password: this.password
        }
        this.$emit('set-action-update-password', data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal_custom {
    .button {
      width: 140px;
    }
  }
</style>
